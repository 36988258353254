.login-page {
    background: url('../assets/Polygon\ Luminary.svg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-form-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}

.login-form-container {
    margin: 0 auto;
    max-width: 100%;
    height: fit-content;
    width: 320px;
    padding: 1rem;
    border: 1px solid var(--ion-color-light);
    border-radius: 6px;
    background: var(--ion-color-light);
}

.login-form .title {
    font-size: 1.5rem;
}

.login-form ion-input {
    background: var(--ion-color-light);
    border: 1px solid var(--ion-color-medium);
    border-radius: 6px;
}

.wave-background {
    height: 200px;
    width: 100%;
}

.error-message {
    color: var(--ion-color-danger);
}