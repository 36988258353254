.w-full {
    width: 100%;
}

.truncat {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rounded-md {
    border-radius: 20px;
}

.square {
    aspect-ratio: 1 / 1;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right;
}

.txt-center {
    text-align: center;
}

.txt-bold {
    font-weight: bold;
}
