.text-primary {
    color: var(--ion-color-primary);
}

.text-secondary {
    color: var(--ion-color-secondary);
}

.text-tertiary {
    color: var(--ion-color-tertiary);
}

.text-success {
    color: var(--ion-color-success);
}

.text-warning {
    color: var(--ion-color-warning);
}

.text-danger {
    color: var(--ion-color-danger);
}

.text-medium {
    color: var(--ion-color-medium);
}

.text-light {
    color: var(--ion-color-light);
}