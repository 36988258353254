.gap-1 {
    gap: var(--space-1);
}

.gap-2 {
    gap: var(--space-4);
}

.gap-3 {
    gap: var(--space-4);
}

.gap-3 {
    gap: var(--space-4);
}