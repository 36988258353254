:root {
    --space-1: .5rem;
    --space-2: 1rem;
    --space-3: 2rem;
    --space-4: 4rem;
    --space-edge: 1.4rem;
}

.padding-edge {
    padding: 0 var(--space-edge);
}