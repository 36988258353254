.quickview-list-container {
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.quickview-list-container::-webkit-scrollbar {
    display: none;
}

.quickview-list {
    display: flex;
    gap: .5rem;
}

.quickview-container {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    padding: .75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--oc-gray-1);
}

.quickview-label {
    color: #4A5568;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.quickview-unit {
    color: #4A5568;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    font-size: .75rem;
}

.quickview-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    font-size: 1.5rem;
}

.add-quickview-container {
    justify-content: center;
    align-items: center;
}