.bg-primary {
    background: var(--ion-color-primary);
}

.bg-secondary {
    background: var(--ion-color-secondary);
}

.bg-tertiary {
    background: var(--ion-color-tertiary);
}

.bg-success {
    background: var(--ion-color-success);
}

.bg-warning {
    background: var(--ion-color-warning);
}

.bg-danger {
    background: var(--ion-color-danger);
}

.bg-medium {
    background: var(--ion-color-medium);
}

.bg-light {
    background: var(--ion-color-light);
}